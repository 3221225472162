<template>
  <div class="page">
    <div style="width: 100px; height: 100px; margin: 10px auto">daping</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keybord: '1'
    }
  },
  created() {
    const me = this
  },
  mounted() {},
  methods: {
    pageKeyBord(message) {
      alert('调用成功')
    }
  }
}
</script>
